import { farmerAPI, extensionWorkerAPI,bulkUploadUsersAPI, exportFarmersAPI } from "../../api's/index";
import {commonApiCall } from "../../utils/index";
var qs = require("qs");

export const postAppUserData = async (data) => {
  const response = await commonApiCall({
    method: "post",
    url: farmerAPI,
    data: data,
  });
  return response;
};

export const getFarmerData = async (params) => {
  const response = await commonApiCall({
    method: "get",
    url: farmerAPI,
    params: params,
  });
  return response;
};

export const editUserData = async (data, id) => {
  const response = await commonApiCall({
    method: "put",
    url: farmerAPI,
    data: data,
  });
  return response;
};

export const deleteUserData = async (data) => {
  const response = await commonApiCall({
    method: "delete",
    url: farmerAPI,
    data: data,
  });
  return response;
};

export const getEwData = async (params) => {
  const response = await commonApiCall({
    method: "get",
    url: extensionWorkerAPI,
    params: params,
  });
  return response;
};

export const editEwData = async (data) => {
  const response = await commonApiCall({
    method: "put",
    url: extensionWorkerAPI,
    data: data,
  });
  return response;
};

export const deleteEwData = async (data) => {
  const response = await commonApiCall({
    method: "delete",
    url: extensionWorkerAPI,
    data: data,
  });
  return response;
};

export const bulkUploadFileToS3 = async (data,url) => {
  const headers = {
    "content-type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }
  const response = await commonApiCall({
    method: "put",
    url: url,
    data: data,
    headers: headers
  });
  return response;
}

export const getTaskStatus = async () => {
  const response = await commonApiCall({
    method: "get",
    url: bulkUploadUsersAPI,
  });
  return response
};

export const getPostSignedUrl = async (data)=>{
  const response = await commonApiCall({
    method: "post",
    url: bulkUploadUsersAPI,
    data: data,
  });
  return response;
}

export const exportFarmersData = async (params) => {
  const response = await commonApiCall({
      method: 'get',
      url: exportFarmersAPI,
      params: params
  });
  return response
}